<template>
  <div class="component">
    <promo-head/>
    <!-- {{goto}} : {{tab}} : {{itemsCount}} -->
    <div class="main"
         :class="{'main-edituserpromo':openEditUser}">
      <nav-bar v-if="!planIsLoading" @promoChooseTab="promoChooseTab" :gotoProp="goto"></nav-bar>
      <div class="view-block" style="display: flex;">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import promoHead from "./components/promoPage/head";
import navBar from "./components/promoPage/navBar";

export default {
  name: 'promo-page-events-main',
  components: {
    promoHead,
    navBar,
  },
  computed: {
    ...mapState({
      planEventId: state => state.seatplanStore.eventId,
      planJson: state => state.seatplanStore.planJson,
      planIsLoading: state => state.seatplanStore.isLoading,
      promoPageList: state => state.promoStore.promoPageList,
      contactList: state => state.contactStore.contactList.List,
    })
  },
  watch: {
    'planJson': {
      immediate: true,
      handler() {
        if (
          this.$route.params.external_id
          || (
            this.planEventId === this.$route.params.eventId
            && !this.planIsLoading
            && !this.planJson
          )
        ) {
          this.listSource.type = 'all';
        }
      },
    },
    'listSource.type': {
      immediate: true,
      deep: true,
      handler() {
        this.$store.dispatch('promoStore/setCompaniesListType', this.listSource.type);
      }
    },
    isShowCompany: {
      deep: true,
      handler() {
        if (this.isShowCompany === true) {
          this.companiesDirection = 'column'
        } else {
          this.companiesDirection = 'row';
          this.isShowDocuments = false;
          this.isShowTeam = false;
          this.isShowProfile = false;
        }
      }
    },
    promoPageList: {
      deep: true,
      handler() {
        this.setPage(localStorage.getItem('promoGoto'))
      }
    },
  },
  created() {
    this.formData.event_id = this.$route.params.eventId;

    this.$store.dispatch('seatplanStore/getSeatplanJson', {
      eventId: this.$route.params.eventId,
    });
    if (this.$route.params.external_id) {
      this.listSource.type = 'all';
    }

    // this.callPromoPage();

  },
  data() {
    return {
      openEditUser: false,
      isShowCompany: false,
      isShowTeam: false,
      isShowDocuments: false,
      isShowProfile: false,
      isShowNotes: false,
      openMap: false,
      companiesDirection: 'row',
      userKey: null,
      contactKey: null,
      open: 'send',
      favoriteCompany: false,

      showEvents: true,
      showContacts: false,
      tab: '',
      itemsCount: '',

      external_id: null,
      goto: '',

      profileNavSelected: '',


      listSource: {
        type: 'map'
      },
      formData: {
        event_id: null,
        external_id: null,
        owner_phone: '+380630682880',
      }
    };
  },
  methods: {
    ...mapActions('promoStore', ['promoPageListMy', 'promoPageListAll']),
    showEditUser() {
      this.showEvents = false;
      this.showContacts = false;
      this.openMap = false;
      this.openEditUser = true;
    },
    callPromoPageList() {
      let data = {event_id: this.formData.event_id};

      if (this.listSource.type === 'own') {
        this.promoPageListMy(data);
      }
      if (this.listSource.type === 'all' || this.listSource.type === 'map') {
        this.promoPageListAll(data);
      }
    },
    createPromoPage() {
      let data = {
        external_id: this.formData.external_id,
        owner_phone: this.formData.owner_phone
      };
      let event_id = Number(this.formData.event_id);
      this.$store.dispatch("promoStore/promoPageCreate", {data, event_id});
    },
    setPage(val) {
      switch (val) {
        case 'events':
        case 'all':
        case 'own':
        case 'recommended':
          this.goto = 'events';
          break;
        case 'contacts':
          this.goto = val;
          break;
        case 'map':
          this.goto = val;
          break;
        default:
          this.goto = 'all';
          break;
      }
    },
    promoChooseTab(val) {
      this.tab = val;
      this.listSource.type = val;
      this.setPage(val);
    },
  }
};
</script>

<style scoped lang="scss">

  .head-company-nav {
    text-align: left;
    font-family: "Point Regular", sans-serif;
    font-size: 10px;
    letter-spacing: 0;
    color: #b5b5b5;
    position: relative;
    padding: 8px 0 8px 156px;
  }

  .main {
    position: relative;

    &-edituserpromo {
      height: auto !important;
    }
  }

  .view-block {
    position: relative;
    padding: 33px 90px 7rem; // TODO: put .view-block into some new, common stylesheet
  }

  .right-indent {
    margin-right: 16px;
  }

  .edit-user-page-descr {
    padding-left: 98px;
    padding-top: 33px;
    padding-bottom: 32px;
    min-height: 37px;
  }

</style>
